import { z } from "zod";
import {
  AccordionIdScheme,
  AccordionSetSchema,
  AccordionSetSchemaMobile,
  AdvanceMessagesScheme,
  AdvanceSettingsScheme,
  ButtonIdScheme,
  ButtonReadoutValueScheme,
  ButtonSetSchema,
  ButtonSetSchemaMobile,
  ColorIdScheme,
  ColorReadoutValueScheme,
  DropdownIdScheme,
  DropdownItemScheme,
  DropdownReadoutValueScheme,
  DropdownSetScheme,
  DropdownSetSchemeMobile,
  GlobalAccordionObject,
  GlobalButtonItemSchema,
  GlobalButtonSchema,
  GlobalColorsStylesSchema,
  GlobalDropdownObjectScheme,
  GlobalInputObject,
  GlobalLinkObjectSchema,
  GlobalOptionObject,
  GlobalPalleteColorItemSchema,
  GlobalSnackbarObjectScheme,
  GlobalStylePagesScheme,
  HEXScheme,
  InputFontSchema,
  InputIdScheme,
  InputReadoutValueScheme,
  InputSetSchema,
  InputSetSchemaMobile,
  InputTextColorSchema,
  LinkIdScheme,
  LinkReadoutValueScheme,
  LinkSetSchema,
  LinkSetSchemaMobile,
  MarginSizeScheme,
  OptionIdScheme,
  OptionSetSchema,
  OptionSetSchemaMobile,
  RowWidthScheme,
  SettingBooleanScheme,
  SizeCSSScheme,
  SnackbarIdScheme,
  SnackbarItemScheme,
  SnackbarSetScheme,
  SnackbarSetSchemeMobile,
  SystemPagesScheme,
  TAccordionSetItemSchema,
  TInputSetItemSchema,
  TLinkSetItemSchema,
  TOptionSetItemSchema,
  TTooltipSetItemSchema,
  ThemePagesSectionScheme,
  ThemePagesSlotScheme,
  ThemeSettingsSchemeBasic,
  ThemeSettingsSchemeMobile,
  TooltipGlobalObjectSchema,
  TooltipIdScheme,
  TooltipReadoutValueScheme,
  TooltipSetSchema,
  TooltipSetSchemaMobile,
  TypoIdScheme,
  TypoReadoutValueScheme,
  TypoSetSchema,
  TypoSetSchemaMobile,
  TypographySchema,
  TypographySchemaMobile,
  UrlScheme,
} from "./themeSettings.schemes";

export type HEX = z.infer<typeof HEXScheme>;
export const COLOR_TRANSPARENT = "#";

// type TThemeSettingsId = `${string}$$${number}`;
export type TTypoId = z.infer<typeof TypoIdScheme>;
export type TColorId = z.infer<typeof ColorIdScheme>;
export type TButtonId = z.infer<typeof ButtonIdScheme>;
export type TLinkId = z.infer<typeof LinkIdScheme>;
export type TTooltipId = z.infer<typeof TooltipIdScheme>;
export type TOptionId = z.infer<typeof OptionIdScheme>;
export type TSnackbarId = z.infer<typeof SnackbarIdScheme>;
export type TDropdownId = z.infer<typeof DropdownIdScheme>;
export type TInputId = z.infer<typeof InputIdScheme>;
export type TMargin = z.infer<typeof MarginSizeScheme>;
export type TUrl = z.infer<typeof UrlScheme>;
export type TSizeCSS = z.infer<typeof SizeCSSScheme>;
export type TBoolean = z.infer<typeof SettingBooleanScheme>;
export type TTypoReadoutValue = z.infer<typeof TypoReadoutValueScheme>;
export type TAccordionId = z.infer<typeof AccordionIdScheme>;

export type TSnackbarReadoutValue = {
  id: TSnackbarId | "custom";
  custom: IGlobalSnackbarObject;
};

export type TColorReadoutValue = z.infer<typeof ColorReadoutValueScheme>;

export type TButtonReadoutValue = z.infer<typeof ButtonReadoutValueScheme>;

export type TInputReadoutValue = z.infer<typeof InputReadoutValueScheme>;

export type TLinkReadoutValue = z.infer<typeof LinkReadoutValueScheme>;

export type TAccordionReadoutValue = {
  id: TAccordionId | "custom";
  custom: IGlobalAccordionObject;
};

export type TDropdownReadoutValue = z.infer<typeof DropdownReadoutValueScheme>;

export type TTooltipReadoutValue = z.infer<typeof TooltipReadoutValueScheme>;

export enum AlignmentHorizontalOption {
  LEFT = "LEFT",
  CENTER = "CENTER",
  RIGHT = "RIGHT",
}

export enum AlignmentVerticalOption {
  TOP = "TOP",
  CENTER = "CENTER",
  BOTTOM = "BOTTOM",
}

export enum VerticalPosition {
  TOP = "TOP",
  BOTTOM = "BOTTOM",
}

export enum TRowWidthOption {
  PERCENT = "PERCENT",
  MARGIN = "MARGIN",
}

export enum RowHeightOption {
  CONTENT = "CONTENT",
  FIXED = "FIXED",
}
export enum ButtonActionOption {
  HOVER = "HOVER",
  CLICK = "CLICK",
  REDIRECT = "REDIRECT",
}

export enum StickyRowEffectOption {
  NONE = "NONE",
  SLIDE_IN = "SLIDE_IN",
  PADDING = "PADDING",
  COLOR = "COLOR",
}

export enum CloseButtonPositionOption {
  INSIDE = "INSIDE",
  OUTSIDE = "OUTSIDE",
}

export type TRowWidth = z.infer<typeof RowWidthScheme>;

export enum FontFamilyOptions {
  ROBOTO = "ROBOTO",
  NUNITO = "NUNITO",
  LATO = "LATO",
  MERRIWEATHER = "MERRIWEATHER",
  OPEN_SANS = "OPEN_SANS",
  NOTO_SANS = "NOTO_SANS",
  MONTAGU_SLAB = "MONTAGU_SLAB",
  MONTSERRAT = "MONTSERRAT",
  LEXEND = "LEXEND",
  POPPINS = "POPPINS",
  RALEWAY = "RALEWAY",
  PLAYFAIR_DISPLAY = "PLAYFAIR_DISPLAY",
  CRIMSON_TEXT = "CRIMSON_TEXT",
  PT_SERIF = "PT_SERIF",
}

export enum FontWeightOptionsDeprecated {
  REGULAR = "REGULAR",
  SEMIBOLD = "SEMIBOLD",
  BOLD = "BOLD",
}

export enum FontWeightOptionsLongListOfWeights {
  Weight100 = "Weight100",
  Weight200 = "Weight200",
  Weight300 = "Weight300",
  Weight400 = "Weight400",
  Weight500 = "Weight500",
  Weight600 = "Weight600",
  Weight700 = "Weight700",
  Weight800 = "Weight800",
  Weight900 = "Weight900",
}

export const FontWeightOptionsCombine = {
  ...FontWeightOptionsLongListOfWeights,
  ...FontWeightOptionsDeprecated,
};

export enum SettingPositionOptions {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum OutlineOptions {
  SOLID = "SOLID",
  DASHED = "DASHED",
  DOTTED = "DOTTED",
  NONE = "NONE",
}

export enum SettingActive {
  ON = "ON",
  OFF = "OFF",
}

export type IGlobalPalleteColor = z.infer<typeof GlobalPalleteColorItemSchema>;

export type IGlobalColorStyles = z.infer<typeof GlobalColorsStylesSchema>;

export type IInputBackgroundColor = z.infer<typeof GlobalColorsStylesSchema>;

export type IInputTextColor = z.infer<typeof InputTextColorSchema>;

export type IInputLabelColor = Pick<IGlobalColorStyles, "color">;

export type IInputPlaceholderColor = Pick<IGlobalColorStyles, "color">;

export type IInputFont = z.infer<typeof InputFontSchema>;

export type TTypoSet = z.infer<typeof TypoSetSchema>;

export type TTypoSetMobile = z.infer<typeof TypoSetSchemaMobile>;

export type ITypography = z.infer<typeof TypographySchema>;
export type ITypographyMobile = z.infer<typeof TypographySchemaMobile>;

export interface ISpacing {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export interface IRadius {
  topLeft: number;
  topRight: number;
  bottomLeft: number;
  bottomRight: number;
}

export type TGlobalButtonItem = z.infer<typeof GlobalButtonItemSchema>;
export type TGlobalInputItem = z.infer<typeof TInputSetItemSchema>;
export type TGlobalLinkItem = z.infer<typeof TLinkSetItemSchema>;
export type TGlobalTooltipItem = z.infer<typeof TTooltipSetItemSchema>;
export type TGlobalSnackbarItem = z.infer<typeof SnackbarItemScheme>;
export type TGlobalDropdownItem = z.infer<typeof DropdownItemScheme>;
export type TGlobalOptionItem = z.infer<typeof TOptionSetItemSchema>;
export type TGlobalAccordionItem = z.infer<typeof TAccordionSetItemSchema>;

export type TButtonSet = z.infer<typeof ButtonSetSchema>;
export type TButtonSetMobile = z.infer<typeof ButtonSetSchemaMobile>;

export type IGlobalButtonObject = z.infer<typeof GlobalButtonSchema>;

export type TInputSet = z.infer<typeof InputSetSchema>;
export type TInputSetMobile = z.infer<typeof InputSetSchemaMobile>;

export type IGlobalInputObject = z.infer<typeof GlobalInputObject>;

export type TLinkSet = z.infer<typeof LinkSetSchema>;
export type TLinkSetMobile = z.infer<typeof LinkSetSchemaMobile>;

export type IGlobalLinkObject = z.infer<typeof GlobalLinkObjectSchema>;
export type TTooltipSet = z.infer<typeof TooltipSetSchema>;
export type TTooltipSetMobile = z.infer<typeof TooltipSetSchemaMobile>;

export type IGlobalTooltipObject = z.infer<typeof TooltipGlobalObjectSchema>;

export type TSnackbarSet = z.infer<typeof SnackbarSetScheme>;
export type TSnackbarSetMobile = z.infer<typeof SnackbarSetSchemeMobile>;

export type IGlobalSnackbarObject = z.infer<typeof GlobalSnackbarObjectScheme>;

export type TDropdownSet = z.infer<typeof DropdownSetScheme>;
export type TDropdownSetMobile = z.infer<typeof DropdownSetSchemeMobile>;

export type IGlobalDropdownObject = z.infer<typeof GlobalDropdownObjectScheme>;

export type IGlobalStylePages = z.infer<typeof GlobalStylePagesScheme>;

export interface IThemePagesHeaderFooter {
  stickyHeader: boolean;
  fullWidthBgcHeader: boolean;
  fullWidthBgcFooter: boolean;
}

export type IThemePagesSection = z.infer<typeof ThemePagesSectionScheme>;

export type IThemePagesSlot = z.infer<typeof ThemePagesSlotScheme>;

export type ISystemPages = z.infer<typeof SystemPagesScheme>;

export type IAdvanceMessages = z.infer<typeof AdvanceMessagesScheme>;

export type IAdvanceSettings = z.infer<typeof AdvanceSettingsScheme>;

export type IThemeState = z.infer<typeof ThemeSettingsSchemeBasic>;
export type IThemeStateMobile = z.infer<typeof ThemeSettingsSchemeMobile>;

export type TOptionSet = z.infer<typeof OptionSetSchema>;
export type TOptionSetMobile = z.infer<typeof OptionSetSchemaMobile>;
export type IGlobalOptionObject = z.infer<typeof GlobalOptionObject>;

export type TAccordionSet = z.infer<typeof AccordionSetSchema>;
export type TAccordionSetMobile = z.infer<typeof AccordionSetSchemaMobile>;
export type IGlobalAccordionObject = z.infer<typeof GlobalAccordionObject>;

export type IGlobalThemeElement =
  | "typo"
  | "buttons"
  | "inputs"
  | "links"
  | "tooltips"
  | "snackbars"
  | "accordions"
  | "dropdowns"
  | "options"
  | "tableCells";
