import { scrollIntoRowPosition } from "../../boxes/BoxListOfButtons/BoxListOfButtons.methods";
import {
  ILinkContent,
  SelectedGoToOption,
} from "../../settingsPatterns/contentPatterns.types";
import { PortalPath } from "../../shared/portalPath/portalPath";
import { useDynamicPageContext } from "../../structure/Contexts/DynamicPageContext";
import { useRowPopupContext } from "../../structure/Contexts/RowPopupContext";
import { useSectionEffectsContext } from "../../structure/Contexts/SectionEffectsContext";

export const getButtonPaths = (button: ILinkContent) =>
  Object.values(button.forceOrderInsideStack ?? {}).flatMap((el) => el);

export const useLinkContentActions = (path?: PortalPath, id?: number) => {
  const { effectsData } = useSectionEffectsContext();
  const { setActivePopup, closeActivePopup } = useRowPopupContext();
  const { setActivePaths, activePaths } = useDynamicPageContext();

  const getHref = (el: ILinkContent) => {
    if (
      el.selectedGoToOption === SelectedGoToOption.GO_TO_PAGE &&
      el.goToPageSetting?.link
    ) {
      return el.goToPageSetting.link;
    }
  };

  const getTarget = (el: ILinkContent) => {
    if (
      el.selectedGoToOption === SelectedGoToOption.GO_TO_PAGE &&
      el.goToPageSetting?.openInNewTab
    ) {
      return "_blank";
    }
  };

  const goToRow = (link: ILinkContent) => {
    if (path === undefined) return;
    closeActivePopup();
    scrollIntoRowPosition(link, path, effectsData);
  };

  const openPopup = (link: ILinkContent) => {
    const rowIdNumber = Number(link.openPopupSetting?.rowId ?? undefined);
    if (!Number.isNaN(rowIdNumber)) {
      setActivePopup(new PortalPath(`main:x,x-section:x,${rowIdNumber}`));
    }
  };

  const closePopup = (link: ILinkContent) => {
    const rowIdNumber = Number(link.closePopupSetting?.rowId ?? undefined);
    if (!Number.isNaN(rowIdNumber)) {
      closeActivePopup(new PortalPath(`main:x,x-section:x,${rowIdNumber}`));
    }
  };

  const changeOrderInsideStack = (link: ILinkContent) => {
    if (id === undefined) return;
    setActivePaths(
      id,
      getButtonPaths(link).map((el) => new PortalPath(el))
    );
  };

  const initFirstActivePaths = (link: ILinkContent) => {
    if (id === undefined) return;
    if (
      link.selectedGoToOption ===
        SelectedGoToOption.CHANGE_ORDER_INSIDE_STACK &&
      link.forceOrderInsideStack
    ) {
      setActivePaths(
        id,
        getButtonPaths(link).map((el) => new PortalPath(el))
      );
    }
  };

  const getIsActiveButton = (link: ILinkContent) => {
    if (id === undefined)
      return { isActiveButton: false, isBoxSelected: false };

    const buttonPaths = getButtonPaths(link);
    const flatActivePaths = activePaths.flatMap(
      (activePath) => activePath.paths
    );

    return {
      isActiveButton: flatActivePaths.some((path) =>
        buttonPaths.some((buttonPath) => path.isPathEqual(buttonPath))
      ),
      isBoxSelected: !buttonPaths.every(
        (path) => new PortalPath(path).getLastPublication() === "x"
      ),
    };
  };

  const anyActionIsActive = ({
    goToPageSetting,
    goToRowSetting,
    openPopupSetting,
    closePopupSetting,
  }: ILinkContent): boolean => {
    const linkExists =
      goToPageSetting &&
      goToPageSetting.link != null &&
      goToPageSetting.link.length > 0;

    const rowIdExists =
      goToRowSetting &&
      goToRowSetting.rowId != null &&
      goToRowSetting.rowId.toString().length > 0;

    const openRowIdExists =
      openPopupSetting &&
      openPopupSetting.rowId != null &&
      openPopupSetting.rowId.toString().length > 0;

    const closeRowIdExists =
      closePopupSetting &&
      closePopupSetting.rowId != null &&
      closePopupSetting.rowId.toString().length > 0;

    return (
      (linkExists || rowIdExists || openRowIdExists || closeRowIdExists) ??
      false
    );
  };

  const linkContentActions = (link: ILinkContent) => {
    switch (link.selectedGoToOption) {
      case SelectedGoToOption.GO_TO_PAGE:
        return closeActivePopup();
      case SelectedGoToOption.GO_TO_ROW:
        return goToRow(link);
      case SelectedGoToOption.OPEN_POPUP:
        return openPopup(link);
      case SelectedGoToOption.CLOSE_POPUP:
        return closePopup(link);
      case SelectedGoToOption.CHANGE_ORDER_INSIDE_STACK:
        return changeOrderInsideStack(link);
    }
  };

  return {
    linkContentActions,
    getHref,
    getTarget,
    initFirstActivePaths,
    getIsActiveButton,
    anyActionIsActive,
  };
};
