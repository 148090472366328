import cleanDeep from "clean-deep";
import { parse, startOfDay } from "date-fns";
import { produce } from "immer";
import mapValues from "lodash.mapvalues";
import { customAlphabet } from "nanoid";
import { z } from "zod";
import { isClientSide } from "./api/api";
import {
  IBoxPageBuilder,
  IBoxes,
  ILayoutPageBuilder,
  ISectionPageBuilder,
  ISlotPageBuilder,
  PageWithBoxesPageBuilder,
} from "./dto/page/pageBuilder.types";
import { IBoxData } from "./dto/page/union.types";
import { ISrpTransformResponseV2 } from "./dto/searchResults.types";
import {
  TButtonReadoutValue,
  TGlobalButtonItem,
} from "./dto/themeSettings/themeSettings.types";

export const isB2BClient = (): boolean =>
  isClientSide() &&
  (sessionStorage.getItem("contractor-token") ??
    localStorage.getItem("contractor-token")) !== null;

export const nanoidNumber = (lenght?: number) =>
  parseInt(customAlphabet("1234567890", lenght ?? 16)(), 10);

const removeNulls = (
  object: Record<string, unknown> | null
): Record<string, unknown> =>
  object === null
    ? {}
    : cleanDeep(object, {
        nullValues: true,
        emptyArrays: false,
        emptyObjects: false,
        emptyStrings: false,
      });

export const convertContentAndSettings = <T extends IBoxPageBuilder<IBoxData>>(
  box: T
): T => {
  const convertedBox = produce(box, (boxCopy) => {
    const {
      content,
      displaySettings,
      contentSettings,
      mobileContentSettings,
      mobileDisplaySettings,
    } = boxCopy;
    const convContent = removeNulls(content);
    const convDisplaySettings = removeNulls(displaySettings);
    const convContentSettings = removeNulls(contentSettings);
    const convMobileContentSettings = removeNulls(mobileContentSettings);
    const convMobileDisplaySettings = removeNulls(mobileDisplaySettings);
    return {
      ...boxCopy,
      content: convContent,
      displaySettings: convDisplaySettings,
      contentSettings: convContentSettings,
      mobileContentSettings: convMobileContentSettings,
      mobileDisplaySettings: convMobileDisplaySettings,
    };
  });
  return convertedBox;
};

export const conversionMapContentAndSettings = (boxes: IBoxes): IBoxes => {
  const convertedBox = mapValues(boxes, (box) =>
    convertContentAndSettings(box)
  );
  return convertedBox;
};

const convertDisplaySettings = <
  T extends ISectionPageBuilder | ISlotPageBuilder,
>(
  pageElement: T
): T => {
  const convertedPageElment = produce(pageElement, (elCopy) => {
    return {
      ...elCopy,
      displaySettings: removeNulls(elCopy.displaySettings),
      mobileDisplaySettings: removeNulls(elCopy.mobileDisplaySettings),
    };
  });
  return convertedPageElment;
};

export const convertDisplaySettingsInPage = (
  page: ILayoutPageBuilder
): ILayoutPageBuilder => {
  const convertedSections = page.sections.map((section) => {
    const convertedSection = convertDisplaySettings(section);
    const convertedSlots = section.slots.map((slot) => {
      const cSlot = convertDisplaySettings(slot);
      const convertedSubSections = slot.sections.map((subSection) => {
        const cSubSection = convertDisplaySettings(subSection);
        const cSlotsInSubSec = subSection.slots.map((slotInSubSec) =>
          convertDisplaySettings(slotInSubSec)
        );
        return { ...cSubSection, slots: cSlotsInSubSec };
      });
      return { ...cSlot, sections: convertedSubSections };
    });
    return { ...convertedSection, slots: convertedSlots };
  });
  return { ...page, sections: convertedSections };
};

export const convertPageWithBoxes = (
  pageWithBoxes: PageWithBoxesPageBuilder
): PageWithBoxesPageBuilder => {
  const convertedPage = convertDisplaySettingsInPage(pageWithBoxes.page);
  return produce(pageWithBoxes, (dataCopy: PageWithBoxesPageBuilder) => {
    const convertedBoxes = conversionMapContentAndSettings(dataCopy.boxes);
    const convertedHeaderBoxes = conversionMapContentAndSettings(
      dataCopy.headerBoxes
    );
    const convertedFooterBoxes = conversionMapContentAndSettings(
      dataCopy.footerBoxes
    );
    return {
      page: convertedPage,
      boxes: convertedBoxes,
      headerBoxes: convertedHeaderBoxes,
      footerBoxes: convertedFooterBoxes,
    };
  });
};

export const getDifferenceMessagesSettingsValidation = <
  Schema extends z.AnyZodObject,
>(
  schemaObject: Schema,
  appData: Record<string, string>
): string[] => {
  const difference: string[] = [];

  for (const key in schemaObject.shape) {
    if (!(key in appData)) {
      difference.push(key);
    }
  }

  return difference;
};

export const getLineAttributesDetectedV2 = (
  productsVm: ISrpTransformResponseV2[]
) => {
  const map: Record<keyof Partial<ISrpTransformResponseV2>, boolean> = {};
  productsVm.forEach((el) => {
    Object.entries(el).forEach(([key, value]) => {
      if (map[key] === undefined) {
        map[key] = false;
      }
      if (value) {
        map[key] = true;
      }
    });
  });
  return map;
};
export const getDateParsed = (date: string) =>
  parse(date, "yyyy-MM-dd", startOfDay(new Date()));

export const parseTimeToTimezoneOffset = (time: number) => {
  const sign = time > 0 ? "-" : "+";
  const hours = String(Math.floor(Math.abs(time) / 60)).padStart(2, "0");
  const minutes = String(Math.abs(time) % 60).padStart(2, "0");
  return `${sign}${hours}:${minutes}`;
};

export const hasNativeIcon = (
  targetButtonStyle: TButtonReadoutValue,
  globalButtonsStyles: TGlobalButtonItem[]
) => {
  const { id, custom } = targetButtonStyle;
  if (id === "custom") {
    return (
      typeof custom?.icon.iconUrl === "string" && custom?.icon.iconUrl !== ""
    );
  }
  const selectedButtonThemeSetting = globalButtonsStyles.find(
    (globalButtonStyle) => globalButtonStyle.id === id
  );
  if (!selectedButtonThemeSetting) return false;
  return (
    typeof selectedButtonThemeSetting.icon.iconUrl === "string" &&
    selectedButtonThemeSetting.icon.iconUrl !== ""
  );
};
