import React from "react";
import { useRouter } from "next/router";
import styled from "styled-components";
import {
  IGlobalPalleteColor,
  TColorId,
  TColorReadoutValue,
  TLinkId,
  TLinkReadoutValue,
  TLinkSet,
  TTypoId,
  TTypoReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import composeCss from "../../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import ConditionalHTMLElement from "../ConditionalHTMLElement/ConditionalHTMLElement";
import { IIcon } from "../icons.types";
import Link from "next/link";
import { isAbsoluteUrl } from "@ecp-boxes/helpers/helpers";

export interface ILinkWrapperProps<T> {
  href: string | undefined | null;
  children: T;
  linkStyle?: TLinkReadoutValue | TLinkId | "basic";
  fontStyle?: TTypoReadoutValue | TTypoId;
  basicColor?: TColorReadoutValue | TColorId;
  icon?: IIcon;
  show?: boolean;
  openInNewTab?: boolean;
  onClick?: () => void;
  className?: string;
  renderAs?: keyof JSX.IntrinsicElements;
}

interface IStyledLinkWrapper {
  $linkStyle?: TLinkReadoutValue | TLinkId | "basic";
  $fontStyle?: TTypoReadoutValue | TTypoId;
  basicColor?: TColorId;
}

const generateLinkStyle = (
  linkStyle: TLinkReadoutValue | TLinkId | "basic",
  globalLink: TLinkSet,
  colorP: IGlobalPalleteColor[],
  isActive?: boolean
): string => {
  if (linkStyle === "basic") {
    return `color: blue; cursor: pointer; :hover { text-decoration: underline }`;
  } else {
    return composeCss.link(linkStyle, globalLink, colorP, isActive);
  }
};

export const StyledLinkWrapper = styled(ConditionalHTMLElement).attrs(
  (props) => ({
    renderAs: props?.["renderAs"] ?? "a",
  })
)<IStyledLinkWrapper>`
  ${(props) => {
    const {
      $linkStyle,
      $fontStyle,
      $basicColor,
      $isActive,
      icon,
      theme: { globalObjects, colorPalette, typography },
    } = props;
    return `
    ${
      $linkStyle !== undefined
        ? generateLinkStyle(
            $linkStyle,
            globalObjects.links,
            colorPalette,
            $isActive
          )
        : ""
    } 
    ${$fontStyle !== undefined ? composeCss.font($fontStyle, typography) : ""}
    ${
      $basicColor !== undefined
        ? `color: ${convertColorIdToHex($basicColor, colorPalette)};`
        : ""
    }
    ${icon ? composeCss.buttonSettings.icon(icon) : ""};

    .complaint-form__file-delete {
      padding: 5px 10px;
      text-align: right;
    }
    `;
  }}
`;

const LinkWrapper: React.FC<
  ILinkWrapperProps<string | JSX.Element | JSX.Element[]>
> = <T extends string | JSX.Element | JSX.Element[]>({
  href,
  children,
  linkStyle,
  fontStyle,
  basicColor,
  icon,
  show = true,
  openInNewTab = false,
  onClick,
  className,
  renderAs = "div",
}: ILinkWrapperProps<T>) => {
  const router = useRouter();
  if (!show) return null;
  if (href === null || href === undefined) {
    return children ? (children as JSX.Element) : null;
  }

  if (href === "") {
    return (
      <StyledLinkWrapper
        role="link"
        onClick={onClick}
        $linkStyle={linkStyle}
        $fontStyle={fontStyle}
        $basicColor={basicColor}
        icon={icon}
        className={className}
        data-testid="empty-link-wrapper"
      >
        {children}
      </StyledLinkWrapper>
    );
  }

  const isActive = href && href !== "/" && router?.asPath === href;

  if (isActive) {
    return (
      <Link href={href} locale={isAbsoluteUrl(href) ? false : undefined}>
        <StyledLinkWrapper
          renderAs={renderAs}
          onClick={() => {
            const scrollIntoView = document.getElementById("scrollTopElement");
            scrollIntoView?.scrollIntoView({ behavior: "smooth" });
          }}
          $linkStyle={linkStyle}
          $fontStyle={fontStyle}
          $basicColor={basicColor}
          icon={icon}
          $isActive={true}
          className={`${className} currentUrl`}
          data-testid="active-link-wrapper"
        >
          {children}
        </StyledLinkWrapper>
      </Link>
    );
  }

  return (
    <Link
      href={href}
      className={className}
      locale={isAbsoluteUrl(href) ? false : undefined}
    >
      <StyledLinkWrapper
        renderAs={renderAs}
        onClick={onClick}
        $linkStyle={linkStyle}
        $fontStyle={fontStyle}
        $basicColor={basicColor}
        icon={icon}
        $isActive={false}
        target={openInNewTab ? "_blank" : "_self"}
        className={className}
        data-testid="link-wrapper"
        {...(href[0] !== "/" ? { rel: "noreferrer" } : {})}
      >
        {children}
      </StyledLinkWrapper>
    </Link>
  );
};

export default LinkWrapper;
